<app-add-in-header></app-add-in-header>
<div
  class="container"
  [ngClass]="documentIds.length > 1 ? 'multi-file-container' : 'one-file-container'"
>
  <app-file-selector
    *ngIf="documentIds.length > 1"
    [attachments]="getFileSelectorAttachments()"
    (selectAttachmentEvent)="selectAttachment($event)"
    [currentAttachmentIndex]="currentAttachmentIndex"
  ></app-file-selector>
  <div class="document-viewer-container">
    <document-viewer-widget
      [base64Content]="documents[currentAttachmentIndex]?.fileContent"
      [showClassificationButton]="false"
      (startClassification)="startAttachmentClassification()"
    ></document-viewer-widget>
  </div>
  <div
    *ngIf="showClassificationResults"
    class="classification-results-container"
  >
    <app-classification-results
      [currentMostConfidentDocument]="currentMostConfidentDocument"
      (closeResultsEvent)="closeClassificationResults()"
    ></app-classification-results>
  </div>
  <app-key-fields
    *ngIf="
      (isAutoClassificationEnabled && documents[currentAttachmentIndex]?.classificationStarted) ||
      (!isAutoClassificationEnabled && !isAutoclassificationFlagLoading)
    "
    [docAreas]="areas"
    [docFormGroup]="documentForm"
    (changeEvent)="formOnChange($event)"
    [attachmentStatus]="documents[this.currentAttachmentIndex]?.status"
    [filteredDocumentTypes]="filteredDocumentTypes"
    [currentDocumentType]="currentDocumentType"
    (uploadDocumentEvent)="uploadDocument()"
    (cancelFileFilingEvent)="cancelFileFiling()"
    [showCancelButton]="true"
    [isAutoClassificationEnabled]="isAutoClassificationEnabled"
  ></app-key-fields>
</div>
<p-toast
  position="bottom-right"
  key="uploadDoneToast"
  styleClass="upload-done-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="toast-message-container">
      <div class="icon-container">
        <fa-icon [icon]="faCircleCheck"></fa-icon>
      </div>
      <div class="message-container">
        <span class="message-summary">{{ message.summary | translate }}</span>
        <span>{{ message.detail | translate }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast
  position="bottom-right"
  key="errorToast"
  styleClass="error-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="toast-message-container">
      <div class="icon-container">
        <fa-icon [icon]="faExclamationCircle"></fa-icon>
      </div>
      <div class="message-container">
        <span class="message-summary">{{ message.summary | translate }}</span>
        <span>{{ message.detail | translate }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
