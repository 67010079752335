import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Attachment } from '../../models/attachment';
import { StatusResponse } from '../../models/statusResponse';
import { Router } from '@angular/router';

export interface FileSelectorAttachment {
  name: string;
  completed: boolean;
  status: StatusResponse;
  classificationStarted: boolean;
}

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss']
})
export class FileSelectorComponent {
  @Input() attachments: FileSelectorAttachment[] = [];
  @Input() currentAttachmentIndex: number = 0;
  @Output() selectAttachmentEvent = new EventEmitter<number>();
  @Output() selectedAttachmentsEvent = new EventEmitter<Attachment[]>();
  classificationStarted: boolean = false;
  selectedAttachments: Attachment[] = [];
  selectedAll: boolean = false;
  attachmentsTitle = this.isPrinter() ? 'documents' : 'attachments';

  constructor(private router: Router) {}

  get headerLabel(): string {
    return this.classificationStarted ? 'fileSelectorHeader1' : 'fileSelectorHeader2';
  }

  isPrinter(): boolean {
    return this.router.url.includes('/printer');
  }

  selectAttachment(index: number): void {
    this.selectAttachmentEvent.emit(index);
  }

  closeDialog(): void {
    // Signal to Outlook to close the dialog window
    Office.context.ui.messageParent(JSON.stringify({ status: 'closeDialog' }));
  }
}
