import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

export interface ITenantUrlPayload {
  securityUrl: string;
  formsUrl: string;
  centralUrl: string;
  vanityId: string;
  contentUrl: string;
  contentApiUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class TenantApiService {
  BASE_URL = environment.urls.tenantApi;

  constructor(private http: HttpClient) {}

  /**
   * Gets the tenant urls for a Softdocs tenant
   * @param tenantId Softdocs tenant id
   */
  getTenantUrls(tenantId: string): Observable<ITenantUrlPayload> {
    const requestUrl = `${this.BASE_URL}/etrieve/${tenantId}`;
    return this.http.get<ITenantUrlPayload>(requestUrl).pipe(catchError(throwError));
  }
}
