<div class="container">
  <div class="file-seletion-header">
    <div class="head-label">
      <span>{{ attachmentsTitle | translate }}</span>
    </div>
  </div>
  <div class="files-container">
    <div *ngIf="attachments.length === 0 && !isPrinter()">
      <span class="no-attachments-label">{{ 'noAttachments' | translate }}</span>
    </div>
    <div
      *ngIf="isPrinter() && attachments.length === 0"
      class="files-spinner-container"
    >
      <ngx-spinner
        color="#006CFA"
        bdColor="rgba(255,255,255, 0.3)"
        [fullScreen]="false"
        type="ball-spin-clockwise"
        size="small"
      ></ngx-spinner>
    </div>
    <div
      class="file-option-container"
      *ngFor="let file of attachments; let i = index"
    >
      <div
        class="file-option"
        [class.disabled]="file.completed"
        (click)="!file.completed && selectAttachment(i)"
        [ngClass]="{ 'active-file': currentAttachmentIndex === i }"
        tabindex="0"
      >
        <span>
          {{ file.name }}
        </span>
        <div class="check-container">
          <img
            *ngIf="file.completed"
            class="check"
            src="../../../assets/check.svg"
            alt="check"
          />
        </div>
        <div
          class="spinner-container"
          *ngIf="!file.status && file.classificationStarted"
        >
          <ngx-spinner
            color="#006CFA"
            bdColor="rgba(255,255,255, 0.3)"
            [fullScreen]="false"
            [showSpinner]="!file.status"
            type="ball-spin-clockwise"
            size="small"
          ></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
