<div class="container">
  <div class="key-fields-header">
    <div class="head-label">
      <span>{{ 'documentDetails' | translate }}</span>
    </div>
  </div>
  <form
    [formGroup]="docFormGroup"
    (ngSubmit)="uploadDocument($event)"
    *ngIf="attachmentStatus || !isAutoClassificationEnabled"
    class="key-fields-form"
    (keydown.enter)="$event.preventDefault()"
    tabindex="0"
  >
    <div class="key-fields-container">
      <div class="input-container area">
        <span class="input-label">{{ 'area' | translate }}</span>
        <select
          class="input-field"
          formControlName="area"
          id="areaSelector"
        >
          <option
            *ngFor="let area of docAreas"
            [value]="area.code"
          >
            {{ area.name }}
          </option>
        </select>
      </div>
      <div class="input-container type">
        <span class="input-label">{{ 'documentType' | translate }}</span>
        <select
          class="input-field"
          formControlName="documentTypeCode"
          id="documentTypeSelector"
        >
          <option value=""></option>
          <option
            *ngFor="let docType of filteredDocumentTypes"
            [value]="docType.code"
          >
            {{ docType.name }}
          </option>
        </select>
      </div>
      <div
        class="input-container"
        *ngFor="let keyField of currentDocumentType?.keyfields"
      >
        <span class="input-label">{{ keyField.name | translate }}</span>
        <p-autoComplete
          #autoComplete
          #Lookup
          *ngIf="keyField.dataType === 'Lookup'"
          styleClass="prime-autocomplete-override"
          [formControlName]="keyField.code"
          [ngClass]="{ 'input-required': hasErrors(keyField.code) }"
          [suggestions]="getLookupSuggestions(keyField.code)"
          (completeMethod)="searchLookup($event, keyField)"
          field="name"
          [minLength]="2"
          dropdownMode="current"
          (click)="autoComplete.show()"
        />
        <input
          *ngIf="keyField.dataType === 'Date'"
          type="date"
          [formControlName]="keyField.code"
          [ngClass]="{ 'input-required': hasErrors(keyField.code) }"
        />
        <input
          *ngIf="keyField.dataType === 'Number'"
          type="number"
          [formControlName]="keyField.code"
          [ngClass]="{ 'input-required': hasErrors(keyField.code) }"
        />
        <select
          *ngIf="keyField.dataType === 'Text' && keyField.constraint"
          [formControlName]="keyField.code"
        >
          <option
            *ngFor="let constraintOption of keyField.constraint"
            [value]="constraintOption"
          >
            {{ constraintOption }}
          </option>
        </select>
        <input
          *ngIf="keyField.dataType === 'Money'"
          type="text"
          [formControlName]="keyField.code"
          (blur)="transformToCurrency($event)"
          [ngClass]="{ 'input-required': hasErrors(keyField.code) }"
        />
        <input
          *ngIf="
            (keyField.dataType === 'Text' || keyField.dataType === 'Decimal') &&
            !keyField.multiline &&
            !keyField.multivalue &&
            !keyField.constraint
          "
          type="text"
          [formControlName]="keyField.code"
          [ngClass]="{ 'input-required': hasErrors(keyField.code) }"
        />
        <div
          *ngIf="keyField.multivalue"
          class="multiple-value-container"
        >
          <div
            *ngFor="let value of multiValueFields[keyField.code]; let i = index"
            class="multiple-option-container"
          >
            <fa-icon
              class="x-mark"
              (click)="removeMultipleValue(keyField.code, i)"
              [icon]="faXmark"
            ></fa-icon>
            <span class="value">
              {{ value }}
            </span>
          </div>
          <input
            *ngIf="keyField.dataType === 'Text'"
            (keydown.enter)="addMultipleValue(keyField.code)"
            [formControlName]="keyField.code"
          />
        </div>
        <textarea
          *ngIf="keyField.dataType === 'Text' && keyField.multiline"
          rows="4"
          [formControlName]="keyField.code"
          [ngClass]="{ 'input-required': hasErrors(keyField.code) }"
        ></textarea>
        <span
          class="error-label"
          *ngIf="hasErrors(keyField.code)"
          >{{ errorText(keyField.code) }}</span
        >
      </div>
    </div>
    <div class="button-container">
      <button
        *ngIf="showCancelButton"
        class="cancel-button"
        type="button"
        (click)="cancelFileFiling($event)"
      >
        <span>{{ 'cancel' | translate }}</span>
      </button>
      <button
        class="send-button"
        type="submit"
        [disabled]="!docFormGroup.controls['area'].value || !docFormGroup.valid || isUploading"
      >
        <span *ngIf="!isUploading">{{ 'send' | translate }}</span>
        <ngx-spinner
          *ngIf="isUploading"
          color="#fff"
          bdColor="rgba(0,0,0,0.8)"
          type="ball-spin-clockwise"
          size="small"
        ></ngx-spinner>
      </button>
    </div>
  </form>
  <div
    class="spinner-container"
    *ngIf="!attachmentStatus && isAutoClassificationEnabled"
  >
    <ngx-spinner
      color="#006CFA"
      bdColor="rgba(255,255,255, 0.3)"
      [fullScreen]="false"
      [showSpinner]="!attachmentStatus && !isUploading && isAutoClassificationEnabled"
      type="ball-spin-clockwise"
      size="small"
    ></ngx-spinner>
    <span class="spinner-label">
      {{ 'classificationInProgress' | translate }}
    </span>
  </div>
</div>
