import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

export interface AuthPayload {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  expires: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authToken: string | null = null;

  FRONT_EGG_URL = environment.urls.frontegg;

  clientId = environment.frontegg.clientId;
  secret = environment.frontegg.secret;

  constructor(private http: HttpClient) {}

  /**
   * authenticates user using client id and secret
   */
  authenticateUser(): Observable<string> {
    const body = { clientId: this.clientId, secret: this.secret };

    return this.http
      .post<AuthPayload>(this.FRONT_EGG_URL, body)
      .pipe(map((payload: AuthPayload) => payload.access_token));
  }

  /**
   *
   * @param authToken user's auth token
   */
  extractTenantId(authToken: string): string {
    const decodedToken: any = jwtDecode(authToken);
    return decodedToken.tenantId;
  }

  extractUserInfo(authToken: string): { key: string; name: string; tenantId: string } {
    const decodedToken: any = jwtDecode(authToken);

    const key = decodedToken.customClaims.principal_id;
    const name = decodedToken.name;
    const tenantId = decodedToken.tenantId;

    return { key, name, tenantId };
  }

  /**
   *
   * @param token a bearer token
   */
  setAuthToken(token: string): void {
    this.authToken = token;
  }

  /**
   *
   */
  getAuthToken(): string | null {
    return this.authToken;
  }
}
