<url-verification
  *ngIf="!isAccessFlagLoading && !isUrlVerified; else addInContainer"
  [hasAccess]="outlookAddInAccessEnabled"
  [noAccessMessage]="featureFlagErrorText | translate"
  (successfulVerificationEvent)="onSuccessfulVerification()"
></url-verification>
<ng-template #addInContainer>
  <app-add-in-header></app-add-in-header>
  <div class="container">
    <app-file-selector
      [attachments]="getFileSelectorAttachments()"
      (selectAttachmentEvent)="selectAttachment($event)"
      [currentAttachmentIndex]="currentAttachmentIndex"
    ></app-file-selector>
    <div
      *ngIf="currentAttachmentIndex < 0"
      class="all-reviews-done-container"
    >
      <fa-icon [icon]="faFileCircleCheck"></fa-icon>
      <span>
        {{ 'allReviewsDone' | translate }}
      </span>
      <span>
        {{ 'closeWindow' | translate }}
      </span>
      <div class="buttons-container">
        <button
          class="close-button"
          (click)="closeDialog()"
        >
          {{ 'close' | translate }}
        </button>
        <button
          class="go-to-content-button"
          (click)="goToContent()"
        >
          {{ 'goToContent' | translate }}
        </button>
      </div>
    </div>
    <div
      [attr.hidden]="currentAttachmentIndex < 0 ? '' : null"
      class="document-viewer-container"
    >
      <document-viewer-widget
        [base64Content]="attachments[currentAttachmentIndex]?.content?.content"
        [showClassificationButton]="
          !attachments[currentAttachmentIndex]?.classificationStarted && isAutoClassificationEnabled
        "
        (startClassification)="startAttachmentClassification()"
      ></document-viewer-widget>
    </div>
    <div
      *ngIf="showClassificationResults"
      class="classification-results-container"
    >
      <app-classification-results
        [currentMostConfidentDocument]="currentMostConfidentDocument"
        (closeResultsEvent)="closeClassificationResults()"
      ></app-classification-results>
    </div>
    <app-key-fields
      *ngIf="
        currentAttachmentIndex >= 0 &&
        (attachments[currentAttachmentIndex]?.classificationStarted ||
          !isAutoClassificationEnabled) &&
        !isAutoclassificationFlagLoading
      "
      [docAreas]="areas"
      [docFormGroup]="documentForm"
      (changeEvent)="formOnChange($event)"
      [attachmentStatus]="attachments[currentAttachmentIndex]?.status"
      [filteredDocumentTypes]="filteredDocumentTypes"
      [currentDocumentType]="currentDocumentType"
      (uploadDocumentEvent)="uploadDocument()"
      [isAutoClassificationEnabled]="isAutoClassificationEnabled"
    ></app-key-fields>
  </div>
</ng-template>
<p-toast
  position="bottom-right"
  key="uploadDoneToast"
  styleClass="upload-done-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="toast-message-container">
      <div class="icon-container">
        <fa-icon [icon]="faCircleCheck"></fa-icon>
      </div>
      <div class="message-container">
        <span class="message-summary">{{ message.summary | translate }}</span>
        <span>{{ message.detail | translate }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast
  position="bottom-right"
  key="errorToast"
  styleClass="error-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="toast-message-container">
      <div class="icon-container">
        <fa-icon [icon]="faExclamationCircle"></fa-icon>
      </div>
      <div class="message-container">
        <span class="message-summary">{{ message.summary | translate }}</span>
        <span>{{ message.detail | translate }}</span>
        <span *ngIf="message.data">{{ message.data }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
