import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddInComponent } from './pages/add-in/add-in.component';
import { PrinterComponent } from './pages/printer/printer.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/add-in',
    pathMatch: 'full'
  },
  {
    path: 'add-in',
    component: AddInComponent,
    data: { title: 'Softdocs for Outlook' }
  },
  {
    path: 'printer',
    component: PrinterComponent,
    data: { title: 'Softdocs Virtual Printer' }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
