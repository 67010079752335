import { NgModule, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, ApplicationRef } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyPipe } from '@angular/common';

// PrimeNG modules
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { NgxSpinnerModule } from 'ngx-spinner';

// FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// ngx-translate for internationalization
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Frontegg modules
import { FronteggAppModule, FronteggAppService } from '@frontegg/angular';

// Application components
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AddInComponent } from './pages/add-in/add-in.component';
import { PrinterComponent } from './pages/printer/printer.component';
import { HeaderComponent } from './components/header/header.component';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { KeyFieldsComponent } from './components/key-fields/key-fields.component';
import { ClassificationResultsComponent } from './components/classification-results/classification-results.component';

// Services
import { TitleService } from './services/title/title.service';

// HTTP Interceptor
import { AuthInterceptor } from './http-interceptors/auth-interceptor';

// Environment Variables
import { environment } from './environments/environment';

// Function for the TranslateLoader
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AddInComponent,
    PrinterComponent,
    HeaderComponent,
    FileSelectorComponent,
    KeyFieldsComponent,
    ClassificationResultsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AutoCompleteModule,
    ToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    FronteggAppModule.forRoot(environment.frontegg.moduleConfig)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    CurrencyPipe,
    Title,
    TitleService,
    MessageService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule implements DoBootstrap {
  constructor(private _fronteggAppService: FronteggAppService) {}

  ngDoBootstrap(appRef: ApplicationRef) {}
}
